export const plurals = (choice: number): number => {
  const name = new Intl.PluralRules('en-US', { type: 'ordinal' }).select(choice)

  return { zero: 0, one: 1, two: 2, few: 3, many: 4, other: 5 }[name]
}

export default defineI18nConfig(() => ({
  differentDomains: true,
  legacy: false,
  useCookie: false,
  pluralRules: {
    uk: plurals,
    sv: plurals,
    en: plurals,
  },
}))
